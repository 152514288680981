import React, { useState } from "react"
import { Layout } from "components/layouts/Layout/layout"
import { graphql } from "gatsby"
import { AddToCartCombo } from "components/elements/AddToCart/add-to-cart-combo"
import { Popover, Disclosure, Transition } from "@headlessui/react"
import {
  ChevronRightIcon,
  CheckIcon,
  PlusCircleIcon,
  CashIcon,
  SaveIcon,
  ReceiptTaxIcon,
} from "@heroicons/react/solid"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { Seo } from "components/seo"

const Combos = ({ data }) => {
  const [machine, setMachine] = useState(false)
  const [grinder, setGrinder] = useState(null)
  const [machineVariantId, setMachineVariantId] = useState(null)
  const [grinderVariantId, setGrinderVariantId] = useState(null)
  const [machinePrice, setMachinePrice] = useState(null)
  const [grinderPrice, setGrinderPrice] = useState(null)
  const [grinderDiscount, setGrinderDiscount] = useState(null)
  const [machineDiscount, setMachineDiscount] = useState(null)
  const [grinderIsTaxable, setGrinderIsTaxable] = useState(null)
  const [machineIsTaxable, setMachineIsTaxable] = useState(null)
  const [machineHandle, setMachineHandle] = useState(null)
  const [grinderHandle, setGrinderHandle] = useState(null)
  const [machineTitle, setMachineTitle] = useState(null)
  const [grinderTitle, setGrinderTitle] = useState(null)
  const [machineImage, setMachineImage] = useState(null)
  const [grinderImage, setGrinderImage] = useState(null)
  const [machineVendor, setMachineVendor] = useState(null)
  const [grinderVendor, setGrinderVendor] = useState(null)

  const total = parseFloat(machinePrice) + parseFloat(grinderPrice)
  const discount = parseFloat(machineDiscount) + parseFloat(grinderDiscount)
  const newTotal = total - discount

  let machineData = []
  let grinderData = []
  machineData = data.items.edges.filter(
    (item) => item.node.tags.includes("combo-builder-machine") === true
  )
  grinderData = data.items.edges.filter(
    (item) => item.node.tags.includes("combo-builder-grinder") === true
  )

  const discountData = data.discounts.edges
  const metaFields = data.metaFields.edges
  const machineDataWithMetafields = machineData.map((machine) => {
    const metafield = metaFields.find(
      (metafield) => metafield?.node?.productId === machine.node.id
    )
    return {
      ...machine,
      metafield: metafield,
    }
  })

  //similarly for grinders
  const grinderDataWithMetafields = grinderData.map((grinder) => {
    const metafield = metaFields.find(
      (metafield) => metafield?.node?.productId === grinder.node.id
    )
    return {
      ...grinder,
      metafield: metafield,
    }
  })

  const machineList = machineDataWithMetafields.map((machine) => {
    let handleList = []
    let handleListRaw = []
    let handleListArray = []
    let handleListArray2 = []
    if (machine.metafield !== null) {
      machine.node.metafields.forEach((metafield) => {
        if (metafield.key === "product_variants") {
          handleListRaw = machine.metafield.node.value.split("|")
          handleListRaw.forEach((handle) => {
            handleList.push(handle.trim())
          })
        }
      })

      let machineId = 0
      handleListArray = [handleListArray[0], handleListArray[1]]
      handleList.forEach((handle) => {
        handleListArray = handle.split(":")

        machineData.forEach((machine) => {
          if (machine.node.handle === handleListArray[0]) {
            handleListArray2.push(handleListArray)
            handleListArray2[handleListArray2.length - 1].push(machineId++)
          }
        })
      })
    }
    let showAssociation = false
    handleListArray2.forEach((handle) => {
      if (handle[0] === machine.node.handle) {
        showAssociation = true
      }
    })

    const machineAssociations = []
    handleListArray2.forEach((handle) => {
      if (handle[0] === machine.node.handle) {
        machineAssociations.push(handle[1])
      }
    })

    const ownerHandles = []
    handleListArray2.forEach((handle) => {
      if (machineAssociations[0] === 0) {
        ownerHandles.push(handle[0])
      }
    })
    return (
      <>
        {showAssociation === false && ownerHandles.length === 0 ? (
          <div
            key={machine.node.id}
            // className="flex relative p-2 m-1 min-w-max bg-white rounded-md border border-gray-200 shadow-sm snap-center w-fit"
            //frame the div when selected
            className={`${
              machineVariantId === machine.node.variants[0].storefrontId
                ? "border-2 border-idc-darker-blue"
                : "border-2 border-white"
            } min-w-max snap-center flex relative shadow-sm rounded-md border bg-white border-gray-200 p-2 m-1`}
          >
            <div className="flex flex-col justify-center items-center">
              <GatsbyImage
                image={machine.node.featuredImage.gatsbyImageData}
                alt={machine.node.title}
                className="w-32 h-32"
              />
              <h3 className="text-sm font-bold text-center text-gray-800 text-clip">
                {machine.node.title.length > 30 ? (
                  <span className="text-sm font-bold text-gray-800 text-clip">
                    {machine.node.title.slice(0, 30)}-
                    <br />
                    {machine.node.title.slice(30)}
                  </span>
                ) : (
                  <span className="text-sm font-bold text-gray-800 text-clip">
                    {machine.node.title}
                  </span>
                )}
              </h3>
              <p className="text-sm text-gray-600">{machine.node.vendor}</p>
              <p className="text-sm text-gray-600">
                ${machine.node.variants[0].price}
              </p>
              <button
                className={`${
                  machineVariantId === machine.node.variants[0].storefrontId
                    ? "bg-idc-dark-green"
                    : "bg-idc-border-orange"
                } flex flex-row text-white font-bold py-2 px-4 rounded-full shadow text-xs sm:text-sm`}
                onClick={() => {
                  setMachine(machine.node)
                  setMachineVariantId(machine.node.variants[0].storefrontId)
                  setMachinePrice(machine.node.variants[0].price)
                  setMachineDiscount(0)
                  discountData.forEach((discount) => {
                    if (machine.node.tags.includes(discount.node.tag)) {
                      setMachineDiscount(discount.node.discount)
                    }
                  })
                  setMachineTitle(machine.node.title)
                  setMachineImage(machine.node.featuredImage.gatsbyImageData)
                  setMachineVendor(machine.node.vendor)
                  setMachineIsTaxable(machine.node.variants[0].taxable)
                  setMachineHandle(machine.node.handle)
                }}
              >
                Select
                {machineVariantId === machine.node.variants[0].storefrontId ? (
                  "ed"
                ) : (
                  <span></span>
                )}
                {machineVariantId === machine.node.variants[0].storefrontId ? (
                  <CheckIcon className="inline ml-2 w-3 h-3 sm:w-5 sm:h-5" />
                ) : (
                  <SaveIcon className="inline ml-2 w-3 h-3 sm:w-5 sm:h-5" />
                )}
              </button>
            </div>
          </div>
        ) : (
          <>
            {ownerHandles.length > 0 && (
              <div
                key={machine.node.id}
                className={`${
                  ownerHandles.includes(machineHandle)
                    ? "border-2 border-idc-darker-blue"
                    : "border-2 border-white"
                } min-w-max w-fit snap-center flex relative shadow-sm rounded-md border bg-white border-gray-200 p-2 m-1`}
              >
                <div className="flex flex-col justify-center items-center">
                  <GatsbyImage
                    image={machine.node.featuredImage.gatsbyImageData}
                    alt={machine.node.title}
                    className="w-32 h-32"
                  />
                  <h3 className="text-sm font-bold text-center text-gray-800 text-clip">
                    {machine.node.title.split(" - ")[0].length > 30 ? (
                      <span className="text-sm font-bold text-gray-800 text-clip">
                        {machine.node.title.split(" - ")[0].slice(0, 30)}-
                        <br />
                        {machine.node.title.split(" - ")[0].slice(30)}
                      </span>
                    ) : (
                      <span className="text-sm font-bold text-gray-800 text-clip">
                        {machine.node.title.split(" - ")[0]}
                      </span>
                    )}
                  </h3>
                  <p className="text-sm text-gray-600">{machine.node.vendor}</p>
                  <p className="text-sm text-gray-600">
                    ${machine.node.variants[0].price}
                  </p>

                  <Popover>
                    <Popover.Button
                      className={`${
                        // if the machineHandle matches any of the ownerHandles
                        ownerHandles.includes(machineHandle)
                          ? "bg-idc-dark-green"
                          : "bg-idc-border-orange"
                      } flex flex-row text-white font-bold py-2 px-4 rounded-full w-full text-center shadow text-xs sm:text-sm`}
                    >
                      {ownerHandles.includes(machineHandle) ? (
                        <span>Selected</span>
                      ) : (
                        <span>Select Variants</span>
                      )}
                      {ownerHandles.includes(machineHandle) ? (
                        <CheckIcon className="inline ml-2 w-3 h-3 sm:w-5 sm:h-5" />
                      ) : (
                        <PlusCircleIcon className="inline ml-2 w-3 h-3 sm:w-5 sm:h-5" />
                      )}
                    </Popover.Button>

                    <Transition
                      enter="transition-opacity duration-500 ease-out"
                      enterFrom="transform scale-10 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-100 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-10 opacity-0"
                    >
                      <Popover.Overlay className="bg-black z-100" />

                      <Popover.Panel
                        className="flex relative left-0 flex-row justify-center items-center max-w-xs bg-white rounded-md shadow-lg z-100 w-fit lg:max-w-2xl focus:outline-none"
                        as="nav"
                      >
                        <div className="flex flex-wrap justify-center items-center bg-white w-fit">
                          {ownerHandles.map((handle) => {
                            return (
                              <div className="">
                                {machineData
                                  .filter((subItem) => {
                                    return subItem.node.handle === handle
                                  })
                                  .map((subItem) => {
                                    return (
                                      <div
                                        key={subItem.node.id}
                                        className="flex flex-col p-4 px-5 py-4 m-4 bg-white rounded-md border border-gray-200 shadow-sm cursor-pointer z-100 focus:outline-none"
                                        role="none"
                                      >
                                        <div
                                          className="items-center text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                          role="menuitem"
                                        >
                                          <GatsbyImage
                                            image={
                                              subItem.node.featuredImage
                                                .gatsbyImageData
                                            }
                                            alt={subItem.node.title}
                                            className="w-16 h-16"
                                          />
                                          <div className="flex flex-col justify-center items-center">
                                            <h3 className="text-sm font-bold text-gray-800 text-clip">
                                              {
                                                subItem.node.title.split(
                                                  " - "
                                                )[1]
                                              }{" "}
                                              {subItem.node.title.split(
                                                " - "
                                              )[2] ? (
                                                <>
                                                  {" "}
                                                  -{" "}
                                                  {
                                                    subItem.node.title.split(
                                                      " - "
                                                    )[2]
                                                  }
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </h3>

                                            <p className="text-sm text-gray-600">
                                              ${subItem.node.variants[0].price}
                                            </p>

                                            <Popover.Button
                                              className={`${
                                                machineVariantId ===
                                                subItem.node.variants[0]
                                                  .storefrontId
                                                  ? "bg-idc-dark-green"
                                                  : "bg-idc-border-orange"
                                              } flex flex-row text-white font-bold py-2 px-4 rounded-full shadow text-xs sm:text-sm`}
                                              onClick={() => {
                                                setMachine(subItem.node)
                                                setMachineVariantId(
                                                  subItem.node.variants[0]
                                                    .storefrontId
                                                )

                                                setMachinePrice(
                                                  subItem.node.variants[0].price
                                                )
                                                setMachineDiscount(0)
                                                discountData.forEach(
                                                  (discount) => {
                                                    if (
                                                      machine.node.tags.includes(
                                                        discount.node.tag
                                                      )
                                                    ) {
                                                      setMachineDiscount(
                                                        discount.node.discount
                                                      )
                                                    }
                                                  }
                                                )

                                                setMachineTitle(
                                                  subItem.node.title
                                                )

                                                setMachineImage(
                                                  subItem.node.featuredImage
                                                    .gatsbyImageData
                                                )
                                                setMachineVendor(
                                                  subItem.node.vendor
                                                )
                                                setMachineIsTaxable(
                                                  machine.node.variants[0]
                                                    .taxable
                                                )
                                                setMachineHandle(
                                                  subItem.node.handle
                                                )
                                              }}
                                            >
                                              Select
                                              {machineVariantId ===
                                              subItem.node.variants[0]
                                                .storefrontId
                                                ? "ed"
                                                : ""}
                                              {machineVariantId ===
                                              subItem.node.variants[0]
                                                .storefrontId ? (
                                                <CheckIcon
                                                  className="-mr-1 ml-2 w-3 h-3 sm:w-5 sm:h-5"
                                                  aria-hidden="true"
                                                />
                                              ) : (
                                                <SaveIcon
                                                  className="-mr-1 ml-2 w-3 h-3 sm:w-5 sm:h-5"
                                                  aria-hidden="true"
                                                />
                                              )}
                                            </Popover.Button>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })}
                              </div>
                            )
                          })}
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                </div>
              </div>
            )}
          </>
        )}
      </>
    )
  })

  const grinderList = grinderDataWithMetafields.map((grinder) => {
    let handleList = []
    let handleListRaw = []
    let handleListArray = []
    let handleListArray2 = []
    if (grinder.metafield !== null) {
      grinder.node.metafields.forEach((metafield) => {
        if (metafield.key === "product_variants") {
          handleListRaw = grinder.metafield.node.value.split("|")
          handleListRaw.forEach((handle) => {
            handleList.push(handle.trim())
          })
        }
      })

      let grinderId = 0
      handleListArray = [handleListArray[0], handleListArray[1]]
      handleList.forEach((handle) => {
        handleListArray = handle.split(":")

        grinderData.forEach((grinder) => {
          if (grinder.node.handle === handleListArray[0]) {
            handleListArray2.push(handleListArray)
            handleListArray2[handleListArray2.length - 1].push(grinderId++)
          }
        })
      })
    }
    let showAssociation = false
    handleListArray2.forEach((handle) => {
      if (handle[0] === grinder.node.handle) {
        showAssociation = true
      }
    })

    const grinderAssociations = []
    handleListArray2.forEach((handle) => {
      if (handle[0] === grinder.node.handle) {
        grinderAssociations.push(handle[1])
      }
    })

    const ownerHandles = []
    handleListArray2.forEach((handle) => {
      if (grinderAssociations[0] === 0) {
        ownerHandles.push(handle[0])
      }
    })
    return (
      <>
        {showAssociation === false && ownerHandles.length === 0 ? (
          <div
            key={grinder.node.id}
            // className="flex relative p-2 m-1 min-w-max bg-white rounded-md border border-gray-200 shadow-sm snap-center w-fit"
            //frame the div when selected
            className={`${
              grinderVariantId === grinder.node.variants[0].storefrontId
                ? "border-2 border-idc-darker-blue"
                : "border-2 border-white"
            } min-w-max snap-center w-fit flex relative shadow-sm rounded-md border bg-white border-gray-200 p-2 m-1`}
          >
            <div className="flex flex-col justify-center items-center">
              <GatsbyImage
                image={grinder.node.featuredImage.gatsbyImageData}
                alt={grinder.node.title}
                className="w-32 h-32"
              />
              <h3 className="text-sm font-bold text-center text-gray-800 text-clip">
                {grinder.node.title.length > 30 ? (
                  <span className="text-sm font-bold text-gray-800 text-clip">
                    {grinder.node.title.slice(0, 30)}-
                    <br />
                    {grinder.node.title.slice(30)}
                  </span>
                ) : (
                  <span className="text-sm font-bold text-gray-800 text-clip">
                    {grinder.node.title}
                  </span>
                )}
              </h3>
              <p className="text-sm text-gray-600">{grinder.node.vendor}</p>
              <p className="text-sm text-gray-600">
                ${grinder.node.variants[0].price}
              </p>
              <button
                className={`${
                  grinderVariantId === grinder.node.variants[0].storefrontId
                    ? "bg-idc-dark-green"
                    : "bg-idc-border-orange"
                } flex flex-row text-white font-bold py-2 px-4 rounded-full shadow text-xs sm:text-sm`}
                onClick={() => {
                  setGrinder(grinder.node.variants[0])
                  setGrinderVariantId(grinder.node.variants[0].storefrontId)
                  setGrinderPrice(grinder.node.variants[0].price)
                  setGrinderDiscount(0)
                  discountData.forEach((discount) => {
                    if (grinder.node.tags.includes(discount.node.tag)) {
                      setGrinderDiscount(discount.node.discount)
                    }
                  })
                  setGrinderTitle(grinder.node.title)
                  setGrinderImage(grinder.node.featuredImage.gatsbyImageData)
                  setGrinderVendor(grinder.node.vendor)
                  setGrinderIsTaxable(grinder.node.variants[0].taxable)
                  setGrinderHandle(grinder.node.handle)
                }}
              >
                Select
                {grinderVariantId === grinder.node.variants[0].storefrontId ? (
                  "ed"
                ) : (
                  <span></span>
                )}
                {grinderVariantId === grinder.node.variants[0].storefrontId ? (
                  <CheckIcon className="inline ml-2 w-3 h-3 sm:w-5 sm:h-5" />
                ) : (
                  <SaveIcon className="inline ml-2 w-3 h-3 sm:w-5 sm:h-5" />
                )}
              </button>
            </div>
          </div>
        ) : (
          <>
            {ownerHandles.length > 0 && (
              <div
                key={grinder.node.id}
                className={`${
                  ownerHandles.includes(grinderHandle)
                    ? "border-2 border-idc-darker-blue"
                    : "border-2 border-white"
                } min-w-max w-fit snap-center flex relative shadow-sm rounded-md border bg-white border-gray-200 p-2 m-1`}
              >
                <div className="flex flex-col justify-center items-center">
                  <GatsbyImage
                    image={grinder.node.featuredImage.gatsbyImageData}
                    alt={grinder.node.title}
                    className="w-32 h-32"
                  />
                  <h3 className="text-sm font-bold text-center text-gray-800 text-clip">
                    {grinder.node.title.split(" - ")[0].length > 30 ? (
                      <span className="text-sm font-bold text-gray-800 text-clip">
                        {grinder.node.title.split(" - ")[0].slice(0, 30)}-
                        <br />
                        {grinder.node.title.split(" - ")[0].slice(30)}
                      </span>
                    ) : (
                      <span className="text-sm font-bold text-gray-800 text-clip">
                        {grinder.node.title.split(" - ")[0]}
                      </span>
                    )}
                  </h3>
                  <p className="text-sm text-gray-600">{grinder.node.vendor}</p>
                  <p className="text-sm text-gray-600">
                    ${grinder.node.variants[0].price}
                  </p>

                  <Popover>
                    {/* <Popover.Button className="flex flex-row px-4 py-2 w-full text-xs font-bold text-center text-white rounded-full shadow bg-idc-border-orange sm:text-sm"> */}
                    <Popover.Button
                      className={`${
                        // if the grinderHandle matches any of the ownerHandles
                        ownerHandles.includes(grinderHandle)
                          ? "bg-idc-dark-green"
                          : "bg-idc-border-orange"
                      } flex flex-row text-white font-bold py-2 px-4 rounded-full w-full text-center shadow text-xs sm:text-sm`}
                    >
                      {ownerHandles.includes(grinderHandle) ? (
                        <span>Selected</span>
                      ) : (
                        <span>Select Variants</span>
                      )}
                      {ownerHandles.includes(grinderHandle) ? (
                        <CheckIcon className="inline ml-2 w-3 h-3 sm:w-5 sm:h-5" />
                      ) : (
                        <PlusCircleIcon className="inline ml-2 w-3 h-3 sm:w-5 sm:h-5" />
                      )}
                    </Popover.Button>

                    <Transition
                      enter="transition-opacity duration-500 ease-out"
                      enterFrom="transform scale-10 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-100 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-10 opacity-0"
                    >
                      <Popover.Overlay className="bg-black z-100" />

                      <Popover.Panel
                        className="flex relative left-0 flex-row justify-center items-center max-w-xs bg-white rounded-md shadow-lg z-100 w-fit lg:max-w-2xl focus:outline-none"
                        as="nav"
                      >
                        <div className="flex flex-wrap justify-center items-center bg-white w-fit">
                          {ownerHandles.map((handle) => {
                            return (
                              <div className="">
                                {grinderData
                                  .filter((subItem) => {
                                    return subItem.node.handle === handle
                                  })
                                  .map((subItem) => {
                                    return (
                                      <div
                                        key={subItem.node.id}
                                        className="flex flex-col p-4 px-5 py-4 m-4 bg-white rounded-md border border-gray-200 shadow-sm cursor-pointer z-100 focus:outline-none"
                                        role="none"
                                      >
                                        <div
                                          className="items-center text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                          role="menuitem"
                                        >
                                          <GatsbyImage
                                            image={
                                              subItem.node.featuredImage
                                                .gatsbyImageData
                                            }
                                            alt={subItem.node.title}
                                            className="w-16 h-16"
                                          />
                                          <div className="flex flex-col justify-center items-center">
                                            <h3 className="text-sm font-bold text-gray-800 text-clip">
                                              {
                                                subItem.node.title.split(
                                                  " - "
                                                )[1]
                                              }{" "}
                                              {subItem.node.title.split(
                                                " - "
                                              )[2] ? (
                                                <>
                                                  {" "}
                                                  -{" "}
                                                  {
                                                    subItem.node.title.split(
                                                      " - "
                                                    )[2]
                                                  }
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </h3>

                                            <p className="text-sm text-gray-600">
                                              ${subItem.node.variants[0].price}
                                            </p>

                                            <Popover.Button
                                              className={`${
                                                grinderVariantId ===
                                                subItem.node.variants[0]
                                                  .storefrontId
                                                  ? "bg-idc-dark-green"
                                                  : "bg-idc-border-orange"
                                              } flex flex-row text-white font-bold py-2 px-4 rounded-full shadow text-xs sm:text-sm`}
                                              onClick={() => {
                                                setGrinder(
                                                  subItem.node.variants[0]
                                                )
                                                setGrinderVariantId(
                                                  subItem.node.variants[0]
                                                    .storefrontId
                                                )

                                                setGrinderPrice(
                                                  subItem.node.variants[0].price
                                                )
                                                setGrinderDiscount(0)
                                                discountData.forEach(
                                                  (discount) => {
                                                    if (
                                                      grinder.node.tags.includes(
                                                        discount.node.tag
                                                      )
                                                    ) {
                                                      setGrinderDiscount(
                                                        discount.node.discount
                                                      )
                                                    }
                                                  }
                                                )

                                                setGrinderTitle(
                                                  subItem.node.title
                                                )

                                                setGrinderImage(
                                                  subItem.node.featuredImage
                                                    .gatsbyImageData
                                                )
                                                setGrinderVendor(
                                                  subItem.node.vendor
                                                )
                                                setGrinderIsTaxable(
                                                  grinder.node.variants[0]
                                                    .taxable
                                                )
                                                setGrinderHandle(
                                                  subItem.node.handle
                                                )
                                              }}
                                            >
                                              Select
                                              {grinderVariantId ===
                                              subItem.node.variants[0]
                                                .storefrontId
                                                ? "ed"
                                                : ""}
                                              {grinderVariantId ===
                                              subItem.node.variants[0]
                                                .storefrontId ? (
                                                <CheckIcon
                                                  className="-mr-1 ml-2 w-3 h-3 sm:w-5 sm:h-5"
                                                  aria-hidden="true"
                                                />
                                              ) : (
                                                <SaveIcon
                                                  className="-mr-1 ml-2 w-3 h-3 sm:w-5 sm:h-5"
                                                  aria-hidden="true"
                                                />
                                              )}
                                            </Popover.Button>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })}
                              </div>
                            )
                          })}
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                </div>
              </div>
            )}
          </>
        )}
      </>
    )
  })

  const continueButton = (
    <div className="mt-8 ml-4">
      <div className="rounded-md shadow">
        {machineImage && grinderImage ? (
          <div>
            <div className="flex flex-row justify-center items-center">
              <span className="text-center">
                <span className="mb-2 text-xl font-bold">
                  You Have Selected:
                </span>
              </span>
            </div>
            <div className="flex flex-row justify-center">
              <GatsbyImage
                image={machineImage}
                alt={machineTitle}
                className="w-12 h-12 rounded-lg shadow"
              />

              <PlusCircleIcon className="mt-10 w-5 h-5" aria-hidden="true" />
              <GatsbyImage
                image={grinderImage}
                alt={grinderTitle}
                className="w-12 h-12 rounded-lg shadow"
              />
            </div>
            <div className="flex flex-row justify-center w-full">
              <h2 className="mx-4 w-1/2 text-center text-gray-900 prose">
                {machineTitle}
                {machineIsTaxable ? (
                  <span className="text-xs text-gray-600"> (Taxable)</span>
                ) : (
                  <></>
                )}
              </h2>
              <h2 className="mx-4 w-1/2 text-center text-gray-900 prose">
                {grinderTitle}
                {grinderIsTaxable ? (
                  <span className="text-xs text-gray-600"> (Taxable)</span>
                ) : (
                  <></>
                )}
              </h2>
            </div>
          </div>
        ) : null}
        {machineVariantId && grinderVariantId ? (
          <AddToCartCombo
            variantIds={machineVariantId + "," + grinderVariantId}
            available={true}
            tags={["combo"]}
            variants={[machine, grinder]}
            productDetails={{
              title: `${machine} + ${grinder}`,
              productType: "combo",
            }}
            comboVariantId={null}
            quantities={(1, 1)}
            machinePrice={machinePrice}
            vendorName={machineVendor + " + " + grinderVendor}
            discountCode={"COMBO"}
          ></AddToCartCombo>
        ) : (
          <button
            type="button"
            className="flex justify-center items-center px-8 py-3 mx-auto w-3/4 text-base font-medium text-white rounded-md border border-transparent shadow-sm bg-idc-subtle hover:bg-opacity-75"
            aria-disabled="true"
          >
            <div className="flex relative justify-center items-center p-2 w-7 h-7 text-center rounded-full shadow bg-idc-title">
              <span className="absolute top-0 left-0 ml-4 text-3xl text-black"></span>
              3
            </div>
            {machineImage && !grinderImage ? (
              <GatsbyImage
                image={machineImage}
                alt={machineTitle}
                className="w-12 h-12 rounded-lg shadow-2xl"
              />
            ) : null}
            {grinderImage && !machineImage ? (
              <GatsbyImage
                image={grinderImage}
                alt={grinderTitle}
                className="w-12 h-12 rounded-lg shadow-2xl"
              />
            ) : null}
            Select a {!machinePrice && "machine"}{" "}
            {!(grinderPrice || machinePrice) ? " and " : null}
            {!grinderPrice && "grinder"}
          </button>
        )}
        {grinderPrice && machinePrice ? (
          <>
            <div className="flex flex-col justify-center mt-2">
              <span className="flex flex-row justify-center">
                <span className="flex flex-row justify-center text-xl font-bold text-gray-500">
                  <s>${total}</s>
                </span>
                <span className="flex flex-row justify-center text-2xl font-bold text-gray-700">
                  <ChevronRightIcon
                    className="inline w-10 h-10"
                    aria-hidden="true"
                  />
                </span>
                <span className="flex flex-row justify-center text-2xl font-bold text-gray-700">
                  ${newTotal}
                </span>
              </span>
              <span className="flex flex-row justify-center">
                <span className="flex flex-row justify-center p-2 text-2xl font-bold rounded-md bg-idc-light-green text-idc-dark-green">
                  Savings of ${discount}
                  <CashIcon
                    className="w-5 h-5 text-green-500"
                    aria-hidden="true"
                  />
                </span>

                {!machineIsTaxable && !grinderIsTaxable ? (
                  <>
                    {" "}
                    <PlusCircleIcon
                      className="m-3 w-5 h-5"
                      aria-hidden="true"
                    />
                    <span className="flex flex-row justify-center p-2 text-2xl font-bold rounded-md bg-idc-light-green text-idc-dark-green">
                      We Pay the Tax
                      <ReceiptTaxIcon
                        className="w-5 h-5 text-idc-vim"
                        aria-hidden="true"
                      />
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </span>
            </div>
          </>
        ) : null}
      </div>
    </div>
  )

  return (
    <Layout>
      {/* heading and copy before the actual app*/}
      <div className="flex flex-col justify-center items-center">
        <StaticImage
          src="https://cms.idrinkcoffee.com/uploads/BF_Combo_Builder_Header_8854bef1a5.jpg"
          alt="Combos"
          className="w-fit sm:w-1/2"
        />
        <span className="flex flex-col justify-center items-center mb-5 text-sm font-bold text-center text-gray-600 sm:flex-row sm:text-lg w-fit sm:w-3/4">
          Try our Black Friday combo builder. It is very easy to use.
        </span>
        <span className="flex flex-row justify-center items-center -ml-10 w-2/3 sm:w-fit sm:mx-auto">
          <span className="flex flex-col justify-center items-center text-lg font-bold text-gray-700 sm:flex-row lg:text-xl">
            <div className="flex relative justify-center items-center p-2 w-7 h-7 text-center rounded-full shadow bg-idc-light-red">
              <span className="absolute top-0 left-0 text-3xl text-idc-title"></span>
              1
            </div>{" "}
            <span className="w-1/4 text-sm lg:text-xl sm:w-fit">
              Pick an espresso machine
            </span>
          </span>
          <span className="flex flex-row justify-center items-center text-lg font-bold text-gray-700 lg:text-xl">
            <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
          </span>
          <span className="flex flex-col justify-center items-center text-lg font-bold text-gray-700 sm:flex-row lg:text-xl">
            <div className="flex relative justify-center items-center p-2 w-7 h-7 text-center rounded-full shadow bg-idc-light-yellow">
              <span className="absolute top-0 left-0 text-3xl text-idc-title"></span>
              2
            </div>{" "}
            <span className="w-1/4 text-sm lg:text-xl sm:w-fit">
              Pick a grinder
            </span>
          </span>
          <span className="flex flex-row justify-center items-center text-lg font-bold text-gray-700 lg:text-xl">
            <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
          </span>
          <span className="flex flex-col justify-center items-center text-lg font-bold text-gray-700 sm:flex-row lg:text-xl">
            <div className="flex relative justify-center items-center p-2 w-7 h-7 text-center rounded-full shadow bg-idc-light-green">
              <span className="absolute top-0 left-0 ml-4 text-3xl text-idc-title"></span>
              3
            </div>{" "}
            <span className="w-1/4 text-sm lg:text-xl sm:w-fit">
              Add to cart
            </span>
          </span>

          <br />
        </span>
        <p className="text-sm text-right text-gray-600">
          (discount applied on cart page){" "}
        </p>
      </div>

      <Disclosure defaultOpen>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={`${
                open
                  ? "rounded-t-lg bg-idc-background"
                  : "rounded-lg bg-idc-icon"
              } flex justify-between w-full sm:w-3/4 sm:mx-auto px-4 mt-4 py-2 text-base font-bold text-idc-body-title hover:bg-opacity-75 focus:outline-none focus-visible:ring focus-visible:ring-idc-darker-blue focus-visible:ring-opacity-75`}
            >
              <div className="flex relative justify-center items-center p-2 w-8 h-8 text-center rounded-full shadow bg-idc-white-transparent">
                <span className="absolute top-0 left-0 text-3xl text-idc-title"></span>
                1
              </div>
              Select a Machine
            </Disclosure.Button>
            <Disclosure.Panel className="text-gray-500" defaultOpen>
              <div className="flex overflow-auto flex-row flex-nowrap justify-items-center mx-auto rounded-md shadow snap-x sm:flex-wrap sm:gap-2 sm:items-center bg-idc-background sm:w-3/4 sm:justify-evenly sm:overflow-hidden">
                {machineList}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={`${
                open
                  ? "rounded-t-lg bg-idc-background"
                  : "rounded-lg bg-idc-icon"
              } flex justify-between w-full sm:w-3/4 sm:mx-auto px-4 mt-4 py-2 text-base font-bold text-idc-body-title hover:bg-opacity-75 focus:outline-none focus-visible:ring focus-visible:ring-idc-darker-blue focus-visible:ring-opacity-75`}
            >
              <div className="flex relative justify-center items-center p-2 w-8 h-8 text-center rounded-full shadow bg-idc-white-transparent">
                <span className="absolute top-0 left-0 text-3xl text-idc-title"></span>
                2
              </div>
              Select a Grinder
            </Disclosure.Button>
            <Disclosure.Panel className="text-gray-500">
              <div className="flex overflow-auto flex-row flex-nowrap justify-items-center mx-auto rounded-md shadow snap-x sm:flex-wrap sm:gap-2 sm:items-center bg-idc-background sm:w-3/4 sm:justify-evenly sm:overflow-hidden">
                {grinderList}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      {continueButton}
    </Layout>
  )
}

export const query = graphql`
  {
    items: allShopifyProduct(
      filter: {
        tags: { regex: "/(?:combo-builder-)(?:machine|grinder)/" }
        hasOutOfStockVariants: { eq: false }
        onlineStoreUrl: { ne: null }
      }
    ) {
      edges {
        node {
          id
          vendor
          hasOutOfStockVariants
          tags
          priceRangeV2 {
            maxVariantPrice {
              amount
            }
            minVariantPrice {
              amount
            }
          }

          variants {
            product {
              handle
              title
              vendor
              featuredImage {
                gatsbyImageData(
                  layout: FIXED
                  height: 128
                  placeholder: BLURRED
                )
              }
            }
            id
            storefrontId
            title
            taxable
            price
            compareAtPrice
            sku
          }
          handle
          status
          featuredImage {
            gatsbyImageData(layout: FIXED, height: 128, placeholder: BLURRED)
          }
          title
          tags
          metafields {
            key
          }
        }
      }
    }

    metaFields: allShopifyProductMetafield(
      filter: {
        key: { eq: "product_variants" }
        product: { tags: { regex: "/(?:combo-builder-)(?:machine|grinder)/" } }
      }
    ) {
      edges {
        node {
          id
          productId
          key
          namespace
          ownerType
          value
          product {
            id
            handle
          }
        }
      }
    }
    discounts: allComboDiscountsJson {
      edges {
        node {
          discount
          tag
        }
      }
    }
  }
`
export default Combos

export const Head = () => {
  return (
    <Seo>
      <title>Combo Builder</title>
    </Seo>
  )
}
